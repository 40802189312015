<template>
  <div class="all">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: "index",

  data() {
    return {
    }
  }
}
</script>

<style scoped >

.all{
  /*margin:2px auto 0 ;*/
}
</style>
